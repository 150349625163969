import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps"
import { useMount, useSearchParam } from 'react-use'

import { Layout } from '../components'
import { container, padding, colours, bgImage, wrapper, type, pagePaddingTop, introText, splitSections, hoverState, textHover } from '../styles/global'
import { parseACF } from '../utils'
import { media } from '../styles/utils'

import { mapStyle } from '../data/mapStyle'
import { MAPS_API_KEY } from '../constants'

const Contact = (props) => {
	const data = props.previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
	const officeQuery = useSearchParam('office');

	useMount(() => {
		handleQuery(true)
	})

	useEffect(() => {
		handleQuery()
	}, [officeQuery])

	const handleQuery = (onMount) => {
		if (!officeQuery) return;

		setTimeout(() => {
			scrollToSection(officeQuery)
		}, onMount ? 500 : 100);
	}

	const scrollToSection = (sectionSlug) => {
		const section = document.getElementById(sectionSlug)?.getBoundingClientRect();
		
		if (section) {
			const offset = (section.y - 128) + window.scrollY;

			try {
				window.scroll({
					top: offset,
					left: 0,
					behavior: 'smooth',
				});
			} catch (error) {
				window.scrollTo(offset, 0);
			}
		}
	}
		
	const renderText = () => {
		if (!data.introduction) return
		
		return (
			<Intro>
				<Text
					dangerouslySetInnerHTML={{__html: data.introduction}}
				/>
			</Intro>
		)
	}

	const renderNav = () => {
		const items = data.offices.map((item, i) => {
			return (
				<Item 
					key={i}
					onClick={() => scrollToSection(item.slug)}
				>
					{item.title}
				</Item>
			)
		})

		return (
			<Sticky>
				<Nav>
					{items}
				</Nav>
			</Sticky>
		)
	}		

	const renderItems = () => {
		const items = data.offices.map((item, i) => {
			return (
				<Office 
					key={i}
					id={item.slug}
				>
					<OfficeMap
						googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
						loadingElement={<div style={{ height: `100%` }} />}
						containerElement={<div style={{ height: `32.6vw` }} />}
						mapElement={<div style={{ height: `100%` }} />}
						map={item.map}
					/>
									
					<Info>
						<ViewMap>
							<Subheading>{item.title}</Subheading>
							<Subheading
								as={'a'}
								href={`https://maps.google.com/?q=${encodeURI(item?.map?.address)}`}
								target={'_blank'}
							>
								View MAP
							</Subheading>
						</ViewMap>
						<Address
							dangerouslySetInnerHTML={{__html: item.contact_details}}
						/>
					</Info>
				</Office>
			)
		})

		return (
			<Items>
				{items}
			</Items>
		)
	}
	
	const renderSections = () => {
		if (!data.offices) return

		return (
			<Sections>
				{renderNav()}
				{renderItems()}
			</Sections>
		)		
	}

	return (
		<Layout
			meta={data.seo}
			breadcrumb={data.title}
        >
			<Container>
				{renderText()}
				{renderSections()}
			</Container>
		</Layout>	
	)
}

const OfficeMap = withScriptjs(withGoogleMap((props) => {
	const { map } = props

	return (
		<GoogleMap
			defaultZoom={18}
			defaultCenter={{ 
				lat: map.lat, 
				lng: map.lng 
			}}
			defaultOptions={{ 
				styles: mapStyle,
				disableDefaultUI: true,
				zoomControl: true,
			}}
		>
			<Marker 
				icon={{
					path: google.maps.SymbolPath.CIRCLE,
					scale: 11,
					fillColor: map?.city == 'New York' ? colours.blue : colours.red,
					fillOpacity: 1,
					strokeWeight: 0
				}}
				position={{ 
					lat: map.lat, 
					lng: map.lng 
				}} 
			/>
		</GoogleMap>
	)
}))

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``
const Info = styled.div``
const Item = styled.div``
const Items = styled.div``

const Image = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
	${padding}
    ${pagePaddingTop}
	flex-direction: column;
    align-items: center;
	height: 100%;
    padding-bottom: 120px;

	${media.phone`
		padding-bottom: 50px;
	`}
`

// Intro

const Intro = styled.div`
	${introText}
`

// Sections

const Sections = styled.div`
	${splitSections}
`

// Section Nav

const Sticky = styled.div``

const Nav = styled.div`
	display: flex;
	flex-direction: column;
	position: sticky;
	top: 128px;

	${Item} {
		${textHover}
	}
`

// Office

const Address = styled.div``
const ViewMap = styled.div``

const Office = styled.div`
	&:not(:last-child) {
		margin-bottom: 96px;

		${media.phone`
			margin-bottom: 50px;
		`}
	}

	${Info} {
		display: flex;
		justify-content: space-between;
		position: relative;
		margin-top: 20px;

		${Subheading},
		${Address} {
			&, p, a {
				${type.bodySmall};
				line-height: 23px;
			}
		}

		${ViewMap} {
			position: absolute;
			left: 0;
			display: flex;
			flex-direction: column;
		}

		${Address} {
			margin-left: 50%;
		}
	}

	${media.phone`
		> *:first-child {
			height: 256px !important;
		}
	`}
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "contact-social" } }) {
            nodes {
                title
                acf_json
            }
        }
    }
`

export default Contact